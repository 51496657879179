<template>
  <el-dialog :title="editData.isEdit?'编辑':editData.type?'新增子报修类型':'新增报修类型'" :visible.sync="_show" width="600px" >
    <el-form :model="editData" :rules="editRules" ref="reparirItemEditForm" size="medium">
      <el-form-item :label="editData.type==1?'子报修类型':'报修类型'" prop="typeName">
        <el-input maxlength="20" v-model.trim="editData.typeName" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input maxlength="80" type="textarea" max v-model="editData.remark" placeholder="请输入"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
        <el-button @click="_show=false" size="medium">取消</el-button>
        <el-button  type="primary" @click="confirm" size="medium">确定</el-button>
      </span>
  </el-dialog>

</template>

<script>
import {validateSpeCharacter} from "@/utils/validator";
export default {
  name: "repair-item-management-edit",
  props:{
    show:{
      type:Boolean,
      default(){
        return false
      }
    },
    editData:{
      type:Object,
      default() {
        return {

        }
      }
    }
  },
  data(){
    return {
      editRules:{
        typeName:[this.$formValidator.empty('类型名称','blur'),{validator:validateSpeCharacter,trigger: 'blur'}]
      }
    }
  },
  computed:{
    _show:{
      get(){
        return this.show;
      },
      set(val){
        this.$emit('update:show',val);
      }
    }
  },
  watch:{
    _show(newVal,oldVal){
      if(newVal){
        this.$refs.reparirItemEditForm.clearValidate()
      }
    }
  },
  methods:{
    confirm(){
      this.$refs.reparirItemEditForm.validate(valid=>{
        if(valid){
          this._show = false;
          let handler = null;
          let param = {
            id:this.editData.id||'',
            remark:this.editData.remark,
            typeName:this.editData.typeName,
          };
          if(this.editData.isEdit){
            handler = this.$api.property.editRepairItem(param)
          }else{
            if(this.editData.type){   // 新增子项
              handler = this.$api.property.addRepairChildItem(param)
            }else{
              delete param.id
              handler = this.$api.property.addRepairItem(param)
            }
          }
          handler.then(res=>{
            if(res.code==200){
              this.$message.success('操作成功')
              this._show  = false;
              this.$emit("refresh");
            }else{
              this.$message.error(res.msg)
            }

          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>