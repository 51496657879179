<template>
  <div class="repair-item-wrapper flex">
    <div class="search-content searchBox">
      <div class="search-item">
        <label>类型</label>
        <el-input type="text" v-model="filter.typeName" placeholder="请输入类型" size="medium" clearable></el-input>
      </div>
      <div class="search-item button-group pdl16">
        <el-button type="primary" class="btn-search" @click="search()" size="medium">查询</el-button>
        <el-button  class="btn-reset" @click="resetSearch" size="medium">重置</el-button>
      </div>
    </div>
    <div class="table-content inline-flex contentBox">
      <div class="contentTool">
        <el-button @click="addData" class=" float-right mgr10" type="primary" size="medium">新增类型</el-button>
      </div>
      <el-table :data="tableData" class="contentTable" size="medium" row-key="id" :tree-props="{children:'child',hasChildren:'hasChildren'}">
        <el-table-column prop="typeName" label="报修类型"></el-table-column>
        <el-table-column prop="updateTime" label="更新日期"></el-table-column>
        <el-table-column prop="updateUser" label="更新人员">
          <template slot-scope="scope">
            {{ scope.row.updateBySysUserName ? scope.row.updateBySysUserName.username : "无"}}
          </template>
        </el-table-column>
        <el-table-column  label="操作">
          <template  slot-scope="scope">
            <span class="mgr10 blue" v-if="scope.row.level!==3" @click="addChild(scope.row)">新增子类型</span>
            <span class="mgr10 blue" @click="edit(scope.row)">编辑</span>
            <span class="mgr10 colorRed" @click="deleteItem(scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <reconova-pagination @paginationChange="paginationChange" :page-no="pageIndex" :page-size="pageSize" :total="total"></reconova-pagination>
    </div>
    <div class="dialog-wrapper">
      <repair-item-management-edit :show.sync="showEditModal" :editData="editData" @refresh="resetSearch"></repair-item-management-edit>
    </div>
  </div>
</template>

<script>
import ReconovaPagination from "@/components/reconova/reconovaPagination";
import RepairItemManagementEdit from "./edit";
import pagination from "@/mixins/pagination";
export default {
  name: "repair-item-management-list",
  components: {
    RepairItemManagementEdit,
    ReconovaPagination
  },
  mixins:[pagination],
  data(){
    return {
      filter:{
        typeName:'',
      },
      showEditModal:false,
      editData:{},
      tableData:[]
    }
  },
  mounted(){
    this.search()
  },
  methods:{
    // 设置层级
    setLevel(data,level){
      data.forEach(item=>{
        item.level  = level+1;
        if(item.child&&item.child.length){
          this.setLevel(item.child,item.level)
        }
        return item
      })
    },


    // 查询
    search(pageSize=10,pageIndex=1){
      let requestParam = this.getParams(pageSize,pageIndex);
      this.$api.property.getRepairItemList(requestParam).then(response=>{
        this.tableData = response.data.list;
        this.total = response.data.total;
        this.pageIndex = response.data.pageNum;
        this.pageSize = response.data.pageSize;
        this.setLevel(this.tableData,0);
      })
    },

    //  新增类型
    addData(){
      this.showEditModal = true;
      this.editData={
        isEdit:false,
        type:0
      }
    },

    //  新增子类型
    addChild(rowData){
      this.showEditModal = true;
      this.editData={
        isEdit:false,
        id:rowData.id,
        type:1
      };
    },

    edit(rowData){
      this.editData = JSON.parse(JSON.stringify(rowData));
      this.editData.isEdit = true;
      this.showEditModal = true;
    },

    deleteItem(rowData){
      this.$confirm('是否删除？','提示',{
        confirmButtonText:'确定',
        cancelButtonText:'取消',
        type:'warning'
      }).then(()=>{
        this.$api.property.disableRepairItem({id:rowData.id}).then(({data})=>{
          this.$message.success('操作成功');
          this.search()
        })
      }).catch(() => {})
    }

  }
}
</script>

<style scoped>
.el-input,.el-select {
  width: 220px;
}
.el-table{
  flex: 1;
  overflow-y: auto;
}
</style>